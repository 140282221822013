import React from 'react';
import LandingPageHeader from '../components/LandingPageHeader';
import Layout from '../components/Layout';

import { articleStyle } from '../components/Layout/styles';

export default class LandingPage extends React.Component {
  render() {
    return (
      <Layout>
        <div css={articleStyle}>
          <LandingPageHeader />
        </div>
      </Layout>
    );
  }
}
