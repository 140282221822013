import React from 'react';
import LandingLog from './landing-programmer.svg';
import { fancyListCss } from '../Layout/styles';

export default class LandingPageHeader extends React.Component {
  render() {
    const headingCaption = 'consulting, training and products for:';
    const offeringList = [
      'Building out reliable DevOps automation.',
      'Scaling Web Applications + REST APIs.',
      'Continuous Integration / Continuous Delivery.',
      'Migrating to microservices or statically generated sites.',
      'Developing high performance Python, Rust + Linux systems.',
    ];

    const headerCss = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '75%',

      '& h1': {
        lineHeight: '1.5',
        paddingLeft: '15px',
        paddingRight: '15px',
      },

      '& img': {
        flexBasis: '50%',
      },

      '@media screen and (max-width: 660px)': {
        display: 'flex',
        flexDirection: 'column',

        padding: '10px 10px',

        '& img': {
          order: '-1',
        },
      },
    };

    const contentToShow = offeringList.map((option, index) => (
      <li key={`c_${index}`}>{option}</li>
    ));

    return (
      <div
        css={{
          ...fancyListCss,
          alignItems: 'center',
          paddingTop: '25px',
          paddingBottom: '25px',
        }}
      >
        <div css={headerCss}>
          <h1>
            Want to Level Up Your DevOps Without Breaking Your IT Operations?
          </h1>
          <img src={LandingLog} alt='Programmer sitting in front of computer' />
        </div>
        <h2>
          Amber Penguin Software offers
          <br />
          {headingCaption}
        </h2>
        <ul>{contentToShow}</ul>
      </div>
    );
  }
}
